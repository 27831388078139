.app {
    height: 102vh;
    background: #eee;
}

#recaptcha {
    margin-top: 10px;
}

.messageUser {
    overflow-x: auto;
    max-width: 30vw;
    text-align: justify !important;
    color: cadetblue;
    font-weight: 700;
}

.notificationUser {
    overflow-x: auto;
    width: 60vw;
    text-align: justify !important;
    color: cadetblue;
    font-weight: 700;
}

.advisorImage {
    width: 100% !important;
    padding-top: 100% !important;
}

.advisorImage img{
   height: 100% !important; 
   position:  absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
}

div.ui.toggle.checkbox label {
    color: aqua !important;
    transition: all ease-in-out 0.7s ;
}

div.ui.checkbox.doneCheckbox label {
    color: red !important;
    font-weight: 900 !important;
    font-size: large !important;
    text-shadow: 0 0 1px white;
}

div.ui.toggle.checkbox input:focus:checked~label,
div.ui.toggle.checkbox input:checked~label {
    color: rgba(0,0,0,0) !important;
}

.ui.dropdown:not(.button)>.default.text {
    color: rgba(60,60,60,.87) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.timepicker select {
    display:inline !important;
    width:6% !important;
    -moz-appearance: textfield !important;
    -webkit-appearance: none !important;
    appearance: none !important ;
}

.timepicker input {
    padding: 0 1em !important;
}

.timepicker .react-datetime-picker__inputGroup__leadingZero {
    display: none !important;
}

.timepicker .react-datetime-picker__wrapper {
    border: none !important;
}

#search {
    background-color: brown;
    color: white ;
    font-weight: bolder;
}

#search::placeholder {
    color: gainsboro ;
    font-weight: bold;
}

div.ui.fluid.input.filterProject>input {
    background-color: teal;
    color: white ;
    font-weight: bolder;
}

div.ui.fluid.input.filterProject>input::placeholder {
    color: gainsboro ;
    font-weight: bold;
}

#selectCategory select {
    color: white;
    font-weight: bold;
    text-align-last:center;
}

.InfiniteCarouselSlide img {
    width: 100% !important;
    height: 75px !important;
    border-radius: 10px;
    box-shadow: 0 0 3px maroon;
}

#textSlider1 {
    position: absolute;
    left: 0;
    animation: myAnimation1 10s infinite linear ;
    -webkit-animation: myAnimation1 10s infinite linear ;
}

@keyframes myAnimation1 {
    from {
        left: 0;
    }
    to {
        left: 100%;
    }
}

@-webkit-keyframes myAnimation1 {
    from {
        left: 0;
    }
    to {
        left: 100%;
    }
}

#textSlider2 {
    position: absolute;
    left: 0;
    visibility: hidden ;
    animation: myAnimation2 10s infinite linear ;
    -webkit-animation: myAnimation2 10s infinite linear ;
    animation-delay: 5s;
    -webkit-animation-delay: 5s;
}

@keyframes myAnimation2 {
    from {
        left: 0;
        visibility: hidden;
    }
    to {
        left: 100%;
        visibility: visible;
    }
}

@-webkit-keyframes myAnimation2 {
    from {
        left: 0;
    }
    to {
        left: 100%;
    }
}

#textSliderContainer {
    background-color: #314152;
    color: #eee;
    height: 30px;
    width: 100%;
    padding: 3px 3px;
    overflow:hidden !important;
    white-space: nowrap;
}

#mobileMenu {
    display: none;
}

#topMenu {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 7.5vh !important;
}

.height {
    height: 90vh !important;
}

.projectCart {
    width: 30% !important;
}

@media screen and (max-width: 1000px) {
    .height {
        height: 97.5vh !important;
    }

    #textSliderContainer {
        display: none !important;
    }
    #topMenu {
        display: none !important;
    }
    #mobileMenu {
        display: block !important;
    }
}

@media screen and (max-width: 700px) {

    .projectCart {
        width: 100% !important;
    }
    
    button.sc-bdfBwQ.rec.rec-arrow.rec.rec-arrow-left,
    button.sc-bdfBwQ.rec.rec-arrow.rec.rec-arrow-right {
        display: none !important;
    }

    .sc-gsTCUz.tXlBS.rec.rec-item-wrapper {
        width: 100% !important;
        min-width: 172.5px !important;
    }

    .InfiniteCarouselSlide img {
        width: 100% !important;
        height: 120px !important;
        border-radius: 10px;
        box-shadow: 0 0 3px maroon;
    }

    .messageUser {
        max-width: 89vw;
        overflow-x: auto;
    }
    .notificationUser {
        width: 89vw;
        overflow-x: auto;
    }
    #leftSide {
        display: none !important;
    }
    button.sc-bdfBwQ.lgksPe.rec.rec-arrow.rec.rec-arrow-left,
    button.sc-bdfBwQ.iLquPE.rec.rec-arrow.rec.rec-arrow-right {
        display: none !important;
    }
}
